<template lang="pug">
  router-link.entry-thumb.pointer-events-auto(v-if="entry", :to="{name: 'index-imageasset', params:{ imageasset: entry.id}}", :id="'entry-thumb-' + entry.id")
    resp-img.max-w-full.block.select-none(:bg="false", :image="{url: entry.url, width: entry.width, height: entry.height, alt: entry.altText }", :key="entry.url")
    //- img.max-w-full.block.select-none.lazyload(ref="img", :data-src="$src(entry.url)", :alt="entry.title")
    //- h4.pt-6.text-8(v-if="entry.creator.length", :class="{'pl-10': padLeft}")
      template(v-for="(creator, i) in entry.creator") {{i > 0 ? ', ' : '' }}{{creator.title}}
</template>

<script>
export default {
  name: 'EntryThumb',
  props: {
    entry: { type: Object, default: undefined },
    padLeft: { type: Boolean, default: false }
  }
}
</script>

<style>
</style>
