import Vue from 'vue'
import VueGtag from 'vue-gtag'
import router from '@/router'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const gaID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID

// honor doNotTrack?
// or is that more for ad-tracking?
// const doNotTrack = window.navigator.doNotTrack === '1'

if (!gaID) {
  console.warn('Missing environment variable: VUE_APP_GOOGLE_ANALYTICS_ID')
} else {
  (async () => {
    // We recommend to call `load` at application startup.
    const fp = await FingerprintJS.load()

    // The FingerprintJS agent is ready.
    // Get a visitor identifier when you'd like to.
    const result = await fp.get()

    // This is the visitor identifier:
    const visitorId = result.visitorId

    Vue.use(VueGtag, {
      enabled: true,
      config: {
        id: gaID,
        params: {
          client_id: visitorId,
          anonymize_ip: true,
          client_storage: 'none' // no cookie
        }
      },
      pageTrackerTemplate(to) {
        // customize page view object so more useful (instead of just route.name...)
        return {
          page_title: to.name + '__' + to.path,
          page_path: to.path,
          page_location: window.location.href
        }
      }
    }, router)
  })()
}
