<template lang="pug">
  //- (about)
  //- desktop: fills parent, scroll space for sticky panel
  aside.lg-relative.transition-flex-basis.duration-500.pointer-events-none(:class="[aboutVisible ? 'lg-flex-33vw' : 'lg-flex-0']")
    //- sticky body (crops panel when off-screen)
    .static.lg-sticky.lg-z-20.top-0.left-0.w-full.h-screen.overflow-hidden
      //- panel (sliding element on mbl)
      .fixed.z-40.top-0.left-100.w-full.h-screen.lg-absolute.lg-left-0.lg-w-33vw.transform.transition-transform.duration-500.lg-transform-none.flex.flex-col(:class="{'-translate-x-full': aboutVisible}")
        //- empty mobile header space
        .h-40.md-h-60.lg-hidden
        //- body (scrollable)
        .flex-1.bg-black.text-white.pointer-events-auto.p-24.lg-pt-0.text-14.overflow-y-scroll
          header.lg-h-60.flex.items-center.justify-between
            h2 ABOUT
            button(@click="toggleAbout(false)") [X]
          section.children-mt-em.mt-em.lg-mt-0(v-html="doc && doc.description")
    //- close panel overlay
    button.fixed.z-10.overlay.pointer-events-auto(@click="toggleAbout(false)", v-show="aboutVisible")
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'AboutPanel',
  data () {
    return {
      doc: undefined
    }
  },
  computed: {
    ...mapState(['aboutVisible'])
  },
  methods: {
    ...mapMutations(['toggleAbout']),
    async get () {
      this.doc = await this.$store.dispatch('getAbout')
    },
    closeOnOuterClick () {
      console.log('go')
      this.toggleAbout(false)
    }
  },
  mounted () {
    // TODO - fetch on request or batch with header global
    this.get()
  }
}
</script>

<style>
</style>
