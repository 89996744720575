export const queryHeader =
`{
  globalSet(handle: "header") {
    ... on header_GlobalSet {
      categoriesMenu {
        ... on categoriesMenu_group_BlockType {
          categories {
            id,
            title,
            children {
              id,
              title
            }
          }
        }
      }
    }
  }
}`

export const queryAbout =
`{
  globalSet(handle:"about") {
    ...on about_GlobalSet{
      description
    }
  }
}`
