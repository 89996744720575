<template lang="pug">
  .app-header.text-16.lg_text-14
    //- main / sticky header (fills parent)
    .absolute.overlay.z-30.pointer-events-none
      //- sticky body
      .sticky.top-0.left-0.h-screen.w-full.flex.flex-col
        //- bar
        .bg-ios-wht.flex.flex-wrap.border-b.pointer-events-auto
          //- title
          h1.w-full.h-40.md-h-60.flex.items-center.justify-center.lg-w-auto.lg-px-30.lg-ml-10
            router-link(to="/") əlēl
          //- search
          form.w-full.lg-w-auto.lg-flex-1.border-t.lg-border-none.relative(@submit.prevent="query")
            label.sr-only(for="site-search-input") Search
            input.h-40.md-h-60.flex.items-center.justify-center.w-full.text-center.lg-text-left.focus-no-underline(ref="input", id="site-search-input", name="search", type="search", placeholder="SEARCH", v-model="searchInput", :class="{'lg-underline': !searchInput.length}", @focus="e => e.target.select()")
            //- close btn
            button.top-0.right-0.z-10.lg-hidden.absolute.pointer-events-auto.h-full.px-10(@click="filters = !filters") {{ !filters ? 'FILTERS' : 'X' }}
          //- active list
          div.w-full.h-40.md-h-60.flex.items-center.justify-center.border-t.uppercase.lg-w-auto.lg-border-none(v-if="activeCategories.length")
            router-link.inline-block.mx-10.hover-text-gray-500(v-for="cat in activeCategories", :to="filterRt(cat.id)", :key="cat.id")
              | {{'#' + cat.title}}
          //- about link
          button.absolute.top-0.right-0.h-40.w-auto.flex.items-center.justify-center.lg-static.md-h-60.px-10(@click="toggleAbout(!aboutVisible)", :class="{'invisible': $route.meta.about === false}") INFO
        //- (mobile filters overlay)
        .flex-1.w-full.relative.flex.flex-col.overflow-y-scroll.lg-hidden(:class="{'pointer-events-auto': filters}")
          //- scroll body
          .bg-ios-wht.text-center.leading-normal(v-show="filters")
            //- groups...
            ul.p-20.border-b(v-for="group in header.categoriesMenu")
              //- first cats...
              li(v-for="primary in group.categories")
                details
                  summary.focus-outline-none
                    span(:class="uln(primary.id)") {{ primary.title }}
                    <sup> {{primary.children.length}}</sup>
                  ul.leading-loose.mt-6.mb-em
                    li(v-for="secondary in primary.children")
                      router-link(:to="filterRt(secondary.id)")
                          span(:class="uln(secondary.id)") {{secondary.title}}
    //- (desktop filters menu)
    aside.invisible.lg-visible.fixed.w-1x4.top-0.left-0.h-screen.flex.flex-col.z-0.hover-z-20.opacity-15.hover-opacity-100.transition-opacity.duration-200
      .p-20 &nbsp;
      .flex-1.overflow-y-scroll.pb-40.bg-gradient-to-r.from-white.to-transparent.scrollbars-hidden
        .p-40
          nav.-ml-8.mb-15
            button.p-8.hover-opacity-100(@click="setGrid('standard')", :class="{'opacity-25': grid !== 'standard'}")
              .icon-grid
            button.p-8.hover-opacity-100(@click="setGrid('creative')", :class="{'opacity-25': grid !== 'creative'}")
              .icon-grid-creative
          //- groups...
          ul.mb-40(v-for="group in header.categoriesMenu")
            //- first cats...
            li(v-for="primary in group.categories")
              details
                summary.focus-outline-none
                  span.pointer-events-auto.cursor-pointer
                    span(:class="uln(primary.id)") {{ primary.title }}
                    <sup> {{primary.children.length}}</sup>
                ul.lg-ml-24.leading-loose.pt-6.pb-em.pointer-events-auto
                  li(v-for="secondary in primary.children")
                    router-link(:to="filterRt(secondary.id)")
                        span(:class="uln(secondary.id)") {{secondary.title}}
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'AppHeader',
  data () {
    return {
      filters: false,
      searchInput: this.getSearchQuery(this.$route)
    }
  },
  computed: {
    ...mapState(['header', 'grid', 'aboutVisible']),
    categoriesAll () {
      return this.$store.state.categories.all
    },
    activeCategories () {
      const active = this.$route.query.categories || ''
      return this.categoriesAll.filter(cat => active.split(',').includes(cat.id))
    }
  },
  watch: {
    '$route' (to) {
      if (to.name === 'index') {
        this.searchInput = to.query.q ? this.getSearchQuery(to) : ''
      }
    }
  },
  methods: {
    ...mapMutations(['setGrid', 'toggleAbout']),
    query () {
      this.$router.push('/?q=' + encodeURIComponent(this.searchInput))
    },
    filterRt (id) {
      const path = this.$route.fullPath
      const query = path.indexOf('?') > 0 ? path.substring(path.indexOf('?')) : undefined
      const params = new URLSearchParams(query)
      let cats = params.get('categories')
      if (!cats) {
        params.set('categories', id)
      } else {
        cats = cats.split(',')
        if (cats.includes(id)) {
          // remove
          cats.splice(cats.indexOf(id), 1)
        } else {
          // add
          cats.push(id)
        }
        cats = cats.join(',')
        if (!cats.length) {
          params.delete('categories')
        } else {
          params.set('categories', cats)
        }
      }
      return params.toString().length ? '/?' + params.toString() : '/'
    },
    uln (id) {
      return this.$route.query.categories?.split(',').includes(id) ? 'font-bold' : ''
    },
    getSearchQuery (rt, force) {
      return rt.query.q ? decodeURIComponent(rt.query.q) : ''
    }
    // toggleAbout () {
    //   const hash = this.$route.hash === '#about' ? undefined : 'about'
    //   return this.$router.replace({ query: this.$route.query, hash })
    // }
  }
}
</script>

<style>
.app-header{
  & nav{
    &::-webkit-scrollbar{
      display:none;
    }
    & a:hover span{
      text-decoration: underline;
    }
  }

  & details[open] summary span {
    @apply .underline;
  }

  & summary{
    /* hide arrow */
    &::-webkit-details-marker{
      display:none;
    }
    &:focus{
      @apply outline-none cursor-pointer
    }
  }
}

.icon-grid{
  height:1.1rem;
  width:1.1rem;
  background-size:contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='3.05556' height='3.05556' fill='black'/%3E%3Crect y='3.97217' width='3.05556' height='3.05556' fill='black'/%3E%3Crect y='7.94446' width='3.05556' height='3.05556' fill='black'/%3E%3Crect x='3.89581' width='3.05556' height='3.05556' fill='black'/%3E%3Crect x='7.79169' width='3.05556' height='3.05556' fill='black'/%3E%3Crect x='3.89581' y='3.97217' width='3.05556' height='3.05556' fill='black'/%3E%3Crect x='3.89581' y='7.94446' width='3.05556' height='3.05556' fill='black'/%3E%3Crect x='7.79169' y='3.97217' width='3.05556' height='3.05556' fill='black'/%3E%3Crect x='7.79169' y='7.94446' width='3.05556' height='3.05556' fill='black'/%3E%3C/svg%3E%0A");
}

.icon-grid-creative{
  height:1.1rem;
  width:1.1rem;
  background-size:contain;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='11' viewBox='0 0 12 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.72229' width='3.89583' height='3.05556' fill='black'/%3E%3Crect x='8.51398' width='3.05556' height='3.05556' fill='black'/%3E%3Crect x='3.77783' y='7.94446' width='3.89583' height='3.05556' fill='black'/%3E%3Crect x='7.67365' y='3.97217' width='3.89583' height='3.05556' fill='black'/%3E%3C/svg%3E%0A");
}
</style>
