<template lang="pug">
  .entries-grid-creative.flex.flex-wrap.py-200.pointer-events-none
    entry-grid-item-creative.w-full.md-w-1x2(v-for="entry in entries", :key="entry.id", :entry="entry")
</template>

<script>
export default {
  name: 'EntriesGridCreative',
  props: {
    entries: { type: Array, default: () => ([]) }
  }
}
</script>

<style>
</style>
