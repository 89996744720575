<template lang="pug">
  .entries-grid-standard.flex.flex-wrap.justify-evenly
    article.w-1x2.lg-w-1x3.my-50.flex.items-center(v-for="entry in entries")
      entry-thumb.mx-auto.w-40vw.md-w-30vw.lg-w-20vw(:entry="entry")
    .flex-1
</template>

<script>
export default {
  name: 'EntriesGridStandard',
  props: {
    entries: { type: Array, default: () => ([]) }
  }
}
</script>

<style>
</style>
