export default {
  namespaced: true,
  state: {
    all: []
  },
  mutations: {
    setAll (state, cats) {
      state.all = cats
    }  
  },
  actions: {
    getAll ({ commit }) {
      return fetch(process.env.VUE_APP_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: `{
            categories {
              id
              title
            }
          }`
        })
      })
        .then(resp => resp.json())
        .then(resp => commit('setAll', resp.data.categories))
    }
  }
}
