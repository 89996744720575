<template lang="pug">
  div.text-base.flex
    main.relative.flex-1
      app-header
      router-view
    //- about shrinks <main> on lg...
    about-panel
</template>

<script>
import '@/style/_main.css'
export default {
  name: 'Default',
  created () {
    this.$store.dispatch('getHeader'),
    this.$store.dispatch('categories/getAll')
    // this.$store.dispatch('nuxtServerInit') // if not nuxt:universal mode
  }
}
</script>

<style>
  /* lazysizes - fade in on loaded*/
  :not(.no-lazyload-anim) {
    &.lazyload,
    &.lazyloading {
      opacity: 0;
    }
    &.lazyloaded {
      opacity: 1;
      transition: opacity 300ms;
    }
  }
</style>

