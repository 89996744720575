<template lang="pug">
  .resp-img(:class="{'relative': !bg}", :style="{paddingBottom: !bg && dims && `${dims.height / dims.width * 100}%`}", v-if="src")
    //- modern browsers load srcset instead of src
    //- lazysizes will load data-srcset when visible
    img.absolute.top-0.left-0.h-full.w-full(ref="img", :class="[fit, {'lazyload': lazy, 'no-lazyload-anim': !anim}]", :srcset="lazy ? '/loading.gif' : thumb", :data-srcset="thumb", data-sizes="auto", :alt="image.alt || alt", @click="$emit('click', thumb)", @error="onError")
</template>

<script>
export default {
  name: 'RespImg',
  props: {
    image: { type: Object, default: undefined },
    alt: { type: String, default: 'Aro Archive' },
    bg: { type: Boolean, default: false },
    fit: { type: String, default: 'object-cover object-center' },
    lazy: { type: Boolean, default: true },
    anim: { type: Boolean, default: true } // animate lazyload
  },
  data () {
    return {
      thumb: ''
    }
  },
  computed: {
    src () {
      return this.image?.originalSrc || this.image?.src || this.image?.url
    },
    resize () {
      return resizeCloudinary
      // return this.$src
      // return this.url?.includes('shopify.com') ? resizeShopifyImg : resizeImgix
    },
    dims () {
      return this.image?.dimensions || { height: this.image?.height, width: this.image?.width }
    },
    isPortrait () {
      return this.dims?.height > this.dims?.width
    }
  },
  methods: {
    onError () {
      console.warn('@RespImg - thumb load error. loading src...')
      this.thumb = process.env.VUE_APP_ASSET_BASE + this.src
    }
  },
  mounted () {
    // optimized image size, based on el width (must be rendered)
    const size = this.isPortrait && this.bg ? [null, this.$refs.img.offsetHeight] : [this.$el.offsetWidth]
    this.thumb = this.resize(this.src, size)
  }
}

// find image size
export function optimImgSize (length) {
  const sizes = [480, 960, 1280, 1600] // , 2048, 3072, 4096]
  const dpx = window.devicePixelRatio || 1
  length = length * dpx * 0.75 // less density optically ok ? (target 80%)
  // find optimal
  return sizes.find(sz => length <= sz) || sizes[sizes.length - 1]
}

// Cloudinary resizer
export function resizeCloudinary (path, size = []) {
  size[0] = size[0] ? 'w_' + optimImgSize(size[0]) : 'w_auto'
  size[1] = size[1] ? 'h_' + optimImgSize(size[1]) : '' // for some reason h_auto causes 400 error
  size = size.join(',').replace(/,$/, '')
  return process.env.VUE_APP_CLOUDINARY_BASE + `/image/fetch/f_auto,q_auto,${size}/` + process.env.VUE_APP_ASSET_BASE + path
}
</script>

<style>
/* lazysizes - fade in on loaded*/
:not(.no-lazyload-anim) {
  &.lazyload,
  &.lazyloading {
    opacity: 0;
  }
  &.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
  }
}
</style>

<!--
lazysizes loaded image, using "modern srcset" approach
* https://github.com/aFarkas/lazysizes#modern-transparent-srcset-pattern
-->
