import { api } from './index'

export default {
  namespaced: true,
  actions: {
    async query (_, { q, categories, offset = 0, limit = 25, orderBy = 'dateCreated DESC' }) { 
      // search term ?
      let search = q && decodeURIComponent(q)
      search = search ? `"${search}" ` : ''
      // categories ?
      categories = (categories && categories.split(',')) || []
      categories = categories.length ? categories : null
      // fetch!
      const resp = await api(queryAssets, { search, categories, limit, offset, orderBy })
      return resp.data?.assets
    },

    async get (_, id) {
      const resp = await api(queryAsset, { id })
      return resp.data?.asset
    }
  }
}

const assetBody = `
  id
  ... on images_Asset {
    year
    url
    width
    height
    categories(leaves: true) {
      id
      title
    }
    creator {
      title
    }
    roles {
      ... on roles_role_BlockType {
        label
        creators {
          ... on creators_creators_Entry {
            title
          }
        }
      }
    }
  }
`

const queryAssets = `
query ($search: String!, $categories: [QueryArgument], $limit: Int!, $offset: Int, $orderBy: String) {
  assets (volume: "images", search: $search, categories: $categories, limit: $limit, offset: $offset, orderBy: $orderBy) {
    ${assetBody}
  }
}
`

const queryAsset = `
query ($id: [QueryArgument]!) {
  asset (id: $id) {
    ${assetBody}
  }
}
`