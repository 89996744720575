<template lang="pug">
  section.page--index.pt-120.lg-pt-100
    //- (single post as overlay)
    transition(name="none", @afterEnter="lockScroll(true)", @beforeLeave="lockScroll(false)")
      router-view(:key="activeEntry && activeEntry.id", :indexEntry="activeEntry", :siblings="siblings")
    //- results body
    entries-grid-creative(v-if="grid === 'creative'", :entries="entries")
    entries-grid-standard(v-else, :entries="entries")
    //- lazyload resutls observer
    observer.w-full(:threshold="0.01", @visible="query({append: true})", v-if="canLoadMore", style="padding-top:100vh;margin-top:-100vh")
    //- (searching...)
    .fixed.overlay.z-10.bg-white.opacity-50.flex.items-center.justify-center(v-show="!lastQuery") SEARCHING...
</template>

<script>
import { mapState } from 'vuex'
// let lastRt
const orderByDefault = 'RAND()' // 'dateCreated DESC'
export default {
  name: 'Home',
  data () {
    return {
      // assetBase: process.env.NUXT_ENV_ASSET_BASE,
      entries: [],
      querying: false,
      lastQuery: null,
      canLoadMore: false,
      orderBy: orderByDefault
    }
  },
  computed: {
    ...mapState(['grid']),
    activeEntry () {
      return this.entries.find(entry => entry.id === this.$route.params.imageasset)
    },
    siblings () {
      const i = this.entries.findIndex(entry => entry.id === this.$route.params.imageasset)
      return i > -1 ? [this.entries[i - 1], this.entries[i + 1]] : []
    }
  },
  watch: {
    async $route (to, from) {
      // new query...
      if (to.name === 'index' && this.lastQuery !== JSON.stringify(to.query)) {
        this.lastQuery = null
        this.orderBy = orderByDefault
        await this.query({})
        window.scroll(0, 0)
      }
      // on closing entry, scroll to entry
      if (from.name === 'index-imageasset') {
        this.lockScroll(false)
        // after scroll unlocks...
        setTimeout(() => {
          const el = document.getElementById('entry-thumb-' + from.params.imageasset)
          const box = el?.getBoundingClientRect()
          if (box) window.scroll(0, box.top + window.pageYOffset - (window.innerHeight - box.height) / 2)
        }, 1)
      }
    }
  },
  methods: {
    async query ({ append = false }) {
      if (this.querying) return
      try {
        console.log('running query')
        this.querying = true
        this.canLoadMore = false
        // query params
        const pageSize = 24
        const params = {
          limit: pageSize,
          offset: !append ? 0 : this.entries.length,
          ...this.$route.query
        }

        // results orderBy
        // const isRandom = !this.$route.query.q && !this.$route.query.categories?.split(',').length
        // this.orderBy = isRandom && !append ? 'RAND()' : orderByDefault
        params.orderBy = this.orderBy // always random?

        // query...
        const results = await this.$store.dispatch('images/query', params)

        // make sure we dont show duplicates
        const filteredResults = []

        results.forEach(item => {
          if (this.entries.some(entry => entry.id === item.id)) {
            console.log('duplicate')
          } else {
            filteredResults.push(item)
          }
        })

        // handle data
        this.entries = append ? this.entries.concat(filteredResults) : filteredResults
        this.querying = false
        this.lastQuery = JSON.stringify(this.$route.query)
        setTimeout(() => {
          this.canLoadMore = results.length === pageSize
        }, 1000) // prevent infinite loop from DOM delay
      } catch (e) {
        console.error(e)
        // throw new Error({ statusCode: 500, message: e })
      }
    },
    lockScroll (lock) {
      document.body.style.overflow = lock ? 'hidden' : ''
    }
  },
  created () {
    if (this.$route.name !== 'index-imageasset') this.query({})
  }
}
</script>

<style>
/*@media (hover:hover) {
  .page--index article {
    & ul, & figcaption {
      opacity:0;
      transition: opacity 200ms;
    }
    &:hover {
      & ul, & figcaption{
        opacity:1;
      }
    }
  }
}*/
</style>
