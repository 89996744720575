import Vue from 'vue'
import Vuex from 'vuex'
import { queryHeader, queryAbout } from './craft-graphql-queries'
import images from './images.js'
import categories from './categories.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: { images, categories },
  state: {
    header: {},
    aboutVisible: false,
    grid: 'standard' // ...'creative'
  },
  mutations: {
    setHeader (state, doc) {
      state.header = doc
    },
    setGrid (state, type = 'standard') {
      state.grid = type
    },
    toggleAbout (state, bool) {
      state.aboutVisible = bool !== undefined ? bool : !state.aboutVisible
    }
  },
  actions: {
    async getHeader ({ commit }) {
      const resp = await api(queryHeader)
      commit('setHeader', resp.data.globalSet)
    },

    async getAbout () {
      const resp = await api(queryAbout)
      return resp.data.globalSet
    }
  }
})

export const api = async (query, variables) => {
  try {
    let resp = await fetch(process.env.VUE_APP_API, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query, variables })
    })
    resp = await resp.json()
    if (resp.errors) {
      console.error('API ERRORS', resp.errors)
      return Promise.reject(new Error('API'))
    }
    return resp
  } catch (e) {
    console.error('API Error:', e)
  }
}
