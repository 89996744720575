<template lang="pug">
  .entry-item.block(:style="{paddingTop: pt + 'px', marginBottom: mb + 'px'}", :to="'/' + entry.id")
    .flex(:class="{'justify-end': rightAlign}")
      .box-content(:style="{width: fullW ? '100%' : `${w}%`, padding: fullW ? 0 : `${px}%`}")
        entry-thumb(:entry="entry", :padLeft="!px && !rightAlign")
        //- img.max-w-full.block.select-none.cursor-pointer.pointer-events-auto.relative.z-10(ref="img", :src="$src(entry.url)", :alt="entry.title")
        //- figcaption.pt-6.text-8(v-if="entry.creator.length", :class="{'pl-10': !px && !rightAlign}")
          template(v-for="(creator, i) in entry.creator") {{i > 0 ? ', ' : '' }}{{creator.title}}
    //- header.flex.px-10.pt-24.pb-60.relative.z-10.pointer-events-auto(v-show="expand")
      //- roles
      .w-1x2.px-10.uppercase
        ul(v-if="entry.roles.length")
          li.mb-24(v-for="role in entry.roles")
            h6.text-8 {{role.label}}
            h5.text-14(v-for="creator in role.creators") {{creator.title}}
        ul(v-else)
          li(v-for="creator in entry.creator")
            h5.text-14 {{creator.title}}
      //- categories
      ul.flex-1.flex.flex-wrap.text-12
        li.w-1x2.pr-10(v-for="cat in entry.categories")
          router-link.hover-underline(:to="`/?categories=` + cat.id", :key="cat.id") {{'#' + cat.title}}
</template>

<script>
export default {
  name: 'EntryItem',
  props: {
    entry: { type: Object, default: () => ({ image: [{}], creator: [] }) }
  },
  data () {
    return {
      expand: false,
      fullW: false,
      w: 100,
      px: 0,
      pt: 0,
      mb: 0,
      rightAlign: false
    }
  },
  methods: {
    randStyle () {
      const rand = (min = 0, max = 1) => Math.random() * (max - min) + min
      const isPrt = this.entry.height * 1.1 > this.entry.width * 1.1
      const w = isPrt ? 60 : 84
      let px = rand(0, (100 - w) / 3)
      px = px < 5 ? 0 : px
      this.w = w
      this.px = px
      this.mb = rand(90, 180)
      this.rightAlign = Math.random() <= 0.25
    }
  },
  mounted () {
    this.randStyle()
  }
}
</script>

<style>
</style>
