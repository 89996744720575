import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugins/vue-register-components.js'
import 'lazysizes'
import { VueHammer } from 'vue2-hammer'
import './plugins/vue-gtag.js'

Vue.use(VueHammer)

// craft imgs base url via $src
Vue.use({
  install: function (Vue, options) {
    Vue.prototype.$src = function (path) {
      return process.env.VUE_APP_ASSET_BASE + path
    }
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
